import React, { useContext, useState } from "react";
import { Box, Button, Text } from "grommet";
import { useForm } from "react-hook-form";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";
import { navigate } from "gatsby";
import urlpaths from "./urlpaths.json";
import { signUp, confirmSignUp } from "aws-amplify/auth";
import AuthLayout from "components/AuthLayout";

const options = ["Singapore", "USA", "UK", "Australia", "Others"];

export default function Signup() {
  const dispatch = useContext(GlobalDispatchContext);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const {
    formState: { errors },
    control,
    handleSubmit,
    register,
    getValues,
  } = useForm();

  const handlePasswordIcon = () => {
    setHidePassword(!hidePassword);
  };

  async function onSubmit() {
    let { email, password, confirmPassword } = getValues();

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      dispatch({
        type: "SET_USER",
        payload: {
          id: null,
          name: "",
          email: email,
        },
      });

      const { isSignUpComplete, userId, nextStep } = await signUp({
        username: email,
        password: password,
      });
      console.log(userId, nextStep);
      if (nextStep) {
        navigate(urlpaths.confirm);
      }
    } catch (err) {
      console.log("error signing up user", err);
      setError(err);
      return;
    }
    // navigate(urlpaths.confirm);
  }

  return (
    <AuthLayout>
      <Box className="container">
        <h1 className="title">Sign up</h1>

        <form onSubmit={handleSubmit(onSubmit)} id="loginForm">
          <Box align="center" width="100">
            <Box width="large">
              <Box width="100%" align="center" gap="xsmall">
                <Box width="100%">
                  {/* Display error message */}
                  {error && (
                    <Box
                      className="msg-error"
                      margin={{ bottom: "medium" }}
                    >
                      <Text color="status-critical">
                        {error.message || error}
                      </Text>
                    </Box>
                  )}
                  {/* Country Selector */}
                  <Box className="field_row">
                    <label htmlFor="email-sign-up-simple">
                      Country/Area of Residence
                    </label>
                    <div className="input_wrapper">
                      <select
                        className="select_container"
                        {...register("country")}
                      >
                        {options.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Box>

                  {/* Email Field */}
                  <Box className="field_row">
                    <label htmlFor="email-sign-up-simple">
                      Email
                    </label>
                    <div className="input_wrapper">
                      <input
                        id="email-sign-up-simple"
                        aria-invalid={errors.email ? "true" : "false"}
                        className={errors.email ? "error" : ""}
                        {...register("email", {
                          required: "This field is required.",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message:
                              "Must be a valid email. example@yourdomain.com",
                          },
                        })}
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                      />
                    </div>
                    {errors.email && (
                      <div className="msg-error">
                        {errors.email.message}
                      </div>
                    )}
                  </Box>

                  {/* Password Field */}
                  <Box className="field_row">
                    <label htmlFor="password">Password</label>
                    <div className="input_wrapper">
                      <input
                        id="password"
                        aria-invalid={
                          errors.password ? "true" : "false"
                        }
                        className={errors.password ? "error" : ""}
                        {...register("password", {
                          required: "This field is required.",
                        })}
                        name="password"
                        type={hidePassword ? "password" : "text"}
                        placeholder="Enter your password"
                      />
                    </div>
                    {errors.password && (
                      <div className="msg-error">
                        {errors.password.message}
                      </div>
                    )}
                  </Box>

                  {/* Confirm Password Field */}
                  <Box className="field_row">
                    <label htmlFor="confirmPassword">
                      Confirm Password
                    </label>
                    <div className="input_wrapper">
                      <input
                        id="confirmPassword"
                        aria-invalid={
                          errors.confirmPassword ? "true" : "false"
                        }
                        className={
                          errors.confirmPassword ? "error" : ""
                        }
                        {...register("confirmPassword", {
                          required: "Please confirm your password.",
                        })}
                        name="confirmPassword"
                        type={hidePassword ? "password" : "text"}
                        placeholder="Re-enter your password"
                      />
                    </div>
                    {errors.confirmPassword && (
                      <div className="msg-error">
                        {errors.confirmPassword.message}
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
              <Button
                className="primary_button"
                label="Create an account"
                size="large"
                primary
                type="submit"
              />
            </Box>
          </Box>
        </form>
        <Text
          weight="normal"
          className="link_text"
          margin={{ top: "large" }}
        >
          Already registered?
          <button
            className="switch_page"
            onClick={() => navigate("/user/login")}
          >
            Sign in to your account
          </button>
        </Text>
      </Box>
    </AuthLayout>
  );
}
